@import "~antd/dist/antd.less";
body {
  background-color: #e8ebe9;
}

// App.js ==================================================================================Start
.Opslog-logo {
  height: 40px;
  width: 40px;
  margin: 10px;
}

.logo-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  background-color: @primary-color;
}

.title-page {
  color: @white !important;
  margin-top: 10px;
}

#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: @white;
}

// App.js ==================================================================================END

// Homepage.js ==================================================================================START
.Card-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-top: 120px;
}
// Homepage.js ==================================================================================END

// DataPage.js ==================================================================================START
.loader-container {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

// DataPage.js =====================================================================================END

// ReportPage.js ==================================================================================START
.page-header-wrapper {
  padding: 24px;
  background-color: #f5f5f5;
}

@primary-color: #1e784f;@info-color: @primary-color;@success-color: @green-6;@processing-color: @blue-6;@error-color: @red-5;@highlight-color: @red-5;@warning-color: @gold-6;@normal-color: #d9d9d9;@white: #fff;@black: #000;